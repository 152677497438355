import { ElementRef, Renderer2 } from '@angular/core';
import { Directive } from '@angular/core';

@Directive({
  selector: '[opRequiredLabel]'
})
export class RequiredLabelDirective {

  constructor(private el: ElementRef, private _renderer: Renderer2) {
  }

  ngAfterViewInit(){
    let elementContent = `${this.el.nativeElement.innerText}<span class="text-danger font-bold ml-1">*</span>`;
    this._renderer.setProperty(this.el.nativeElement,'innerHTML',elementContent);
  }

}
